import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import { ref } from "vue";
import router from "@/router/routes";

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

export interface AccountInterface {
  id: string;
  name: string;
  websiteUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  tiktokUrl: string;
  instagramUrl: string;
}

export class Price {
  id = null;
  name?: string = "";
  active?: boolean = true;
  currency?: string = "";
  chargeInterval?: string = "";
  plan?: Plan = new Plan();
  stripePriceId?: string = "";
  planName?: string = "";
}

export class PriceTier {
  id = -1;
  flatAmount = 0;
  flatAmountDecimal = "";
  unitAmount = 0;
  unitAmountDecimal = "";
  upTo = 0;
}

export class SubscriptionItem {
  price?: Price = new Price();
  quantity?: number = 0;
  planCategory = -1;
}

export class Subscription {
  id = "";
  stripeSubscriptionId?: string = "";
  subscriptionType?: string = "";
  currentPeriodEnd?: string = "";
  defaultStripePaymentMethodId?: string = "";
  status?: string = "";
  upcomingAmount?: number = 0;

  subscriptionItems: SubscriptionItem[] = [];
}

export class Account {
  id = "";
  name = "";
  websiteUrl?: string = "";
  facebookUrl?: string = "";
  twitterUrl?: string = "";
  tiktokUrl?: string = "";
  instagramUrl?: string = "";
  numberOfLocations?: number = 0;
  billingAddress?: Address = new Address();
  subscriptions: Subscription[] = [];
  primaryBannerAd?: string = "";
  bannerAdLink?: string = "";
}

export class User {
  id = "";
  name = "";
  account = "";
  username = "";
  surname?: string = "";
  email?: string = "";
  newPassword?: string = "";
  api_token?: string = "";
  userLocations: UserLocationArray[] = [];
}

export class UserLocationArray {
  location: Location = new Location();
}

export class Address {
  name = "";
  email = "";
  phoneNumber = "";
  id = "";
  street1 = "";
  street2 = "";
  city = "";
  state = "";
  zip = "";
  country = "";
}

export class Workflow {
  id = "";
  enabled = false;
  account = "";
  name = "";
  workflowTemplate = "";
  workflowPropertyValues = [];
  optInExistingCustomers = false;
  theme = "";
}

export class Theme {
  id = "";
  name = "";
}

export class HQEvent {
  id = "";
  title = "";
  description = "";
  location = "";
  startTime = "";
  endTime = "";
  publishState = "";
}

export class TVListing {
  id = "";
  date = "";
  time = "";
  event = "";
}

export class TVGuideWidget {
  id = "";
  title = "";
  background = "";
  selectedListings = [] as number[];
}

export class Location {
  id = "";
  name = "";
  address?: Address = new Address();
  code?: string = "";
  websiteUrl?: string = "";
  facebookUrl?: string = "";
  twitterUrl?: string = "";
  tiktokUrl?: string = "";
  instagramUrl?: string = "";
}

export class Plan {
  id = "";
  name = "";
  enabledFeatures = [];
  stripeProductId = "";
  planCategory = -1;
  prices = [];
}

export class UserLocation {
  id? = "";
  user = "";
  location = "";
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  account = {} as Account;

  subscriptions = {} as Subscription[];
  loadingLabels = {
    subscriptions: false,
    paymentMethods: false,
  };

  locations = [] as Location[];
  events = [] as HQEvent[];

  tvListings = [] as TVListing[];

  selectedLocation = new Location() as Location;
  userLocations = [] as Location[];
  accountUsers = [] as User[];
  enabledMarketingPrograms = [];
  accountImages = {};
  paymentMethods = [];
  isAuthenticated = !!JwtService.getToken();
  plans = [] as Plan[];
  themes = [] as Theme[];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get getLocations() {
    return this.locations;
  }

  get getEvents() {
    return this.events;
  }

  get getTVListings() {
    return this.tvListings;
  }
  get getSelectedLocation() {
    return this.selectedLocation;
  }

  get getAccount() {
    return this.account;
  }

  get getLoadingLabels() {
    return this.loadingLabels;
  }

  get getPaymentMethods() {
    return this.paymentMethods;
  }

  get getActiveSubscriptionItems() {
    const results = {};
    if ("subscriptions" in this.account) {
      for (let i = 0; i < this.account.subscriptions.length; i++) {
        if (this.account.subscriptions[i].status == "active") {
          for (
            let pi = 0;
            pi < this.account.subscriptions[i].subscriptionItems.length;
            pi++
          ) {
            if (
              !(
                this.account.subscriptions[i].subscriptionItems[pi]
                  .planCategory in results
              )
            ) {
              results[
                this.account.subscriptions[i].subscriptionItems[pi].planCategory
              ] = [];
            }
            results[
              this.account.subscriptions[i].subscriptionItems[pi].planCategory
            ].append(this.account.subscriptions[i].subscriptionItems[pi]);
          }
        }
      }
    }
    return results;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    if (this.user.api_token) {
      JwtService.saveToken(this.user.api_token);
    }
    if (this.selectedLocation.id == "") {
      if ("userLocations" in this.user && this.user.userLocations.length > 0) {
        this.selectedLocation = this.user.userLocations[0].location;
      }
    }
  }

  @Mutation
  [Mutations.SET_PAYMENT_METHODS](paymentMethods) {
    this.paymentMethods = paymentMethods;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_LOCATIONS](locations) {
    this.locations = locations;
    this.account.numberOfLocations = this.locations.length;
  }

  @Mutation
  [Mutations.SET_LOADING_LABEL](value) {
    Object.assign(this.loadingLabels, value);
  }

  @Mutation
  [Mutations.SET_SUBSCRIPTIONS](subscriptions) {
    this.subscriptions = subscriptions;
  }

  @Mutation
  [Mutations.SET_EVENTS](events) {
    this.events = events as HQEvent[];
  }

  @Mutation
  [Mutations.SET_TV_LISTINGS](listings) {
    this.tvListings = listings as TVListing[];
  }

  @Mutation
  [Mutations.SET_SELECTED_LOCATION](location) {
    this.selectedLocation = location;
  }

  @Mutation
  [Mutations.SET_USER_LOCATIONS](locations) {
    this.userLocations = locations;
  }

  @Mutation
  [Mutations.SET_PLANS](plans) {
    this.plans = plans;
  }

  @Mutation
  [Mutations.SET_THEMES](themes) {
    this.themes = themes;
  }

  @Mutation
  [Mutations.SET_ACCOUNT](account) {
    this.account = account;
  }

  @Mutation
  [Mutations.SET_ACCOUNT_USERS](accountUsers) {
    this.accountUsers = accountUsers;
  }

  @Mutation
  [Mutations.SET_ENABLED_MARKETING_PROGRAMS](enabledMarketingPrograms) {
    this.enabledMarketingPrograms = enabledMarketingPrograms;
  }

  @Mutation
  [Mutations.SET_ACCOUNT_IMAGES](accountImages) {
    this.accountImages = {};
    for (let i = 0; i < accountImages.length; i++) {
      this.accountImages["/api/v1/images/" + accountImages[i].id] =
        accountImages[i];
    }
  }

  @Mutation
  [Mutations.SET_ACCOUNT_IMAGE](accountImage) {
    this.accountImages["/api/v1/images/" + accountImage.id] = accountImage;
  }

  @Mutation
  [Mutations.DELETE_ACCOUNT_IMAGE](id) {
    delete this.accountImages["/api/v1/images/" + id];
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.newPassword = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.account = {} as Account;
    this.errors = [];
    JwtService.destroyToken();
    ApiService.setHeader();
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      ...credentials,
    };
    return ApiService.post("api/login", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.REGISTER](credentials) {
    credentials.accountOwner.jobDescription = parseInt(
      credentials.accountOwner.jobDescription
    );
    let success = true;
    await ApiService.post("api/v1/accounts/register", credentials)
      .then(({ data }) => {
        success = true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        success = false;
      });

    return success;
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("api/forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const params = {
        params: {},
      };

      ApiService.query("api/v1/verify_token", params as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data[0]);
          this.context.dispatch(Actions.REFRESH_ACCOUNT);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
          window.location.href = "/";
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      router.push({ name: "sign-in" });
    }
  }

  @Action
  [Actions.REFRESH_ACCOUNT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const params = {
        params: {},
      };
      ApiService.query(this.user.account, params as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ACCOUNT, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [Actions.REFRESH_LOCATION_EVENTS]() {
    if (this.selectedLocation.id != "") {
      const response = await ApiService.get(
        "api/v1/locations/" + this.selectedLocation.id + "/events"
      );
      this.context.commit(Mutations.SET_EVENTS, response.data);
    }
  }

  @Action
  async [Actions.REFRESH_LOCATION_TV_LISTINGS]() {
    if (this.selectedLocation.id != "") {
      const response = await ApiService.get(
        "api/v1/locations/" + this.selectedLocation.id + "/tv_listings"
      );
      this.context.commit(Mutations.SET_TV_LISTINGS, response.data);
    }
  }

  @Action
  async [Actions.REFRESH_ACCOUNT_LOCATIONS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      if (this.context.getters.getLoadingLabels.locations) return;
      try {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          locations: true,
        });
        const response = await ApiService.get(
          "api/v1/accounts/" + acctId + "/locations"
        );
        this.context.commit(Mutations.SET_LOCATIONS, response.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          locations: false,
        });
      }
    }
  }

  @Action
  async [Actions.REFRESH_ACCOUNT_SUBSCRIPTIONS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      if (this.context.getters.getLoadingLabels.subscriptions) return;

      try {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          subscriptions: true,
        });
        const response = await ApiService.get(
          "api/v1/accounts/" + acctId + "/subscriptions"
        );
        this.context.commit(Mutations.SET_SUBSCRIPTIONS, response.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          subscriptions: false,
        });
      }
    }
  }

  @Action
  async [Actions.REFRESH_ACCOUNT_USERS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.get(
        "api/v1/accounts/" + acctId + "/users/"
      );
      this.context.commit(Mutations.SET_ACCOUNT_USERS, response.data);
    }
  }

  @Action
  async [Actions.REFRESH_PAYMENT_METHODS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      if (this.context.getters.getLoadingLabels.paymentMethods) return;

      try {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          paymentMethods: true,
        });
        const response = await ApiService.get(
          "api/v1/accounts/" + acctId + "/payment_methods/"
        );
        this.context.commit(
          Mutations.SET_PAYMENT_METHODS,
          response.data.paymentMethods.data
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.context.commit(Mutations.SET_LOADING_LABEL, {
          paymentMethods: false,
        });
      }
    }
  }

  @Action
  async [Actions.REFRESH_IMAGES]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.get(
        "api/v1/accounts/" + acctId + "/images"
      );
      this.context.commit(Mutations.SET_ACCOUNT_IMAGES, response.data);
    }
  }

  @Action
  async [Actions.REFRESH_ENABLED_MARKETING_PROGRAMS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.get(
        "api/v1/accounts/" + acctId + "/workflows/"
      );
      this.context.commit(
        Mutations.SET_ENABLED_MARKETING_PROGRAMS,
        response.data
      );
    }
  }

  @Action
  async [Actions.REMOVE_PAYMENT_METHOD](method) {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.delete(
        "api/v1/accounts/" +
          acctId +
          "/payment_methods/delete?cardId=" +
          method.id
      );
      this.context.commit(
        Mutations.SET_PAYMENT_METHODS,
        response.data.paymentMethods.data
      );
    }
  }

  @Action
  async [Actions.REFRESH_PLANS]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.get("api/v1/plans");
      this.context.commit(Mutations.SET_PLANS, response.data);
    }
  }

  @Action
  async [Actions.REFRESH_THEMES]() {
    const acctId = this.context.getters.getAccount.id;
    if (acctId !== undefined) {
      const response = await ApiService.get("api/v1/themes");
      this.context.commit(Mutations.SET_THEMES, response.data);
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
